import Vue from 'vue';

import '@takamol/nawa-library/src/assets/scss/theme.scss';
import './assets/scss/main.scss';

import { setupVueGlobal } from '@/setup/vue';
import { setupGlobalComponents, setupGlobalIcons, setupGlobalContainers } from '@/setup/components';
import { setupModules } from '@/setup/modules';
import { setupInterceptors } from '@/setup/axios';
import { setupValidation, setupValidationRules } from '@/setup/validation';
import { loadZEWidget } from '@/helpers/zendesk';
import { loadGTAG } from '@/helpers/gtag';
import { setupSentry } from '@/setup/sentry';
import { setupOAuth } from '@/setup/oauth';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

setupVueGlobal();
setupGlobalComponents();
setupGlobalIcons();
setupGlobalContainers();
setupModules();
setupSentry();
setupInterceptors(store);
setupValidation();
setupValidationRules();
setupOAuth();

//loadZEWidget(i18n);
loadGTAG();

const app = {
  render: (h) => h(App),
  router,
  store,
  i18n,
};

new Vue(app).$mount('#app');
